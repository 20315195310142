export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";

export const ADMIN_PRODUCT_REQUEST = "ADMIN_PRODUCT_REQUEST";
export const ADMIN_PRODUCT_SUCCESS = "ADMIN_PRODUCT_SUCCESS";
export const ADMIN_PRODUCT_FAIL = "ADMIN_PRODUCT_FAIL";

export const GET_PRODUCT_BY_SLUG_REQUEST = "GET_PRODUCT_BY_SLUG_REQUEST";
export const GET_PRODUCT_BY_SLUG_SUCCESS = "GET_PRODUCT_BY_SLUG_SUCCESS";
export const GET_PRODUCT_BY_SLUG_FAIL = "GET_PRODUCT_BY_SLUG_FAIL";

export const ALL_SEARCH_PRODUCT_REQUEST = "ALL_SEARCH_PRODUCT_REQUEST";
export const ALL_SEARCH_PRODUCT_SUCCESS = "ALL_SEARCH_PRODUCT_SUCCESS";
export const ALL_SEARCH_PRODUCT_FAIL = "ALL_SEARCH_PRODUCT_FAIL";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_FAIL = "GET_PRODUCT_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";